<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>

          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->

          <app-bar-options></app-bar-options>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher
            v-if="isDev"
            class="mx-4"
          ></app-bar-theme-switcher>

          <!-- <app-bar-chat class="mx-4"></app-bar-chat> -->
          <!-- <app-bar-notification></app-bar-notification> -->

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <template>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://themeselection.com"
          class="text-decoration-none"
        >ThemeSelection</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>

    <template #v-app-content>
      <div
        v-if="notifications.length"
        :class="[{'navbar-content-container-notification-pl': menuIsVerticalNavMini}, {'content-full': appContentWidth === 'full'}, 'navbar-content-container-notification']"
      >
        <v-alert
          v-for="(notification, index) in notifications"
          :key="index"
          class="navbar-content-container-notification-alert"
          :border="notification.border"
          :type="notification.notification_type"
          :outlined="notification.outlined"
          :text="notification.colored_text"
          :color="notification.notification_color"
          :icon="resolveIcon(notification.icon)"
          :dismissible="notification.dismissible"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="notification.html_code"
            v-html="notification.html_code"
          ></div>
          <template
            v-if="notification.dismissible"
            v-slot:close="{ toggle }"
          >
            <v-icon
              @click="toggleAlert(toggle, notification)"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </template>
        </v-alert>
      </div>
      <!-- <app-chat class="d-none d-md-block"></app-chat> -->
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarOptions from '@core/layouts/components/app-bar/AppBarOptions.vue'

// import AppChat from '@core/layouts/components/app-chat/AppChat.vue'
// import AppBarChat from '@core/layouts/components/app-bar/AppBarChat.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// App Bar Components
// import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'

// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import {
  mdiMenu,
  mdiHeartOutline,
  mdiClose,
  mdiMessageOutline,
} from '@mdi/js'

import { getVuetify, isDev } from '@core/utils'

// Search Data
import {
  ref, watch, onMounted, onUnmounted,
} from '@vue/composition-api'
import appBarSearchData from '@/assets/app-bar-search-data'

import navMenuItems from '@/navigation/vertical'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

import { getConsoleNotificationsActive } from '@api'
import useConsoleNotificationsList from '@/views/console-notifications/useConsoleNotificationsList'
import useAppConfig from '@core/@app-config/useAppConfig'
import useSelectOptions from '@/@core/utils/useSelectOptions'

export default {
  components: {
    LayoutContentVerticalNav,

    // AppChat,
    // AppCustomizer,
    // App Bar Components
    // AppBarSearch,

    // AppBarChat,

    AppBarThemeSwitcher,
    AppBarI18n,
    AppBarUserMenu,
    AppBarOptions,

    // AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()
    const { resolveIcon } = useConsoleNotificationsList()
    const intervalNotification = ref(null)
    /* eslint-disable object-curly-newline, operator-linebreak */
    const { menuIsVerticalNavMini, menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth } =
      useAppConfig()
    const { configOrganization } = useSelectOptions()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    const notifications = ref([])

    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    const fetchNotification = async () => {
      const response = await getConsoleNotificationsActive()
      const notificationsStored = localStorage.getItem('notifications')
      if (notificationsStored) {
        const arrNotifications = JSON.parse(notificationsStored)
        notifications.value = response.data.filter(e => !arrNotifications.includes(e.id) && (e.groups_ids.length === 0 || e.groups_ids.includes(configOrganization.value)))
      } else notifications.value = response.data.filter(e => (e.groups_ids.length === 0 || e.groups_ids.includes(configOrganization.value)))
    }

    const toggleAlert = (toggle, notification) => {
      const notificationsStored = localStorage.getItem('notifications')
      if (notificationsStored) {
        const arrNotifications = JSON.parse(notificationsStored)
        arrNotifications.push(notification.id)
        localStorage.setItem('notifications', JSON.stringify(arrNotifications))
      } else {
        localStorage.setItem('notifications', JSON.stringify([notification.id]))
      }

      const index = notifications.value.findIndex(e => e.id === notification.id)
      if (index > -1) {
        notifications.value = notifications.value.filter(e => e.id !== notification.id)
      }

      // toggle()
    }

    watch(configOrganization, async () => {
      await fetchNotification()
    })

    onMounted(async () => {
      await fetchNotification()

      clearInterval(intervalNotification.value)
      intervalNotification.value = setInterval(() => {
        fetchNotification()
      }, 120000)
    })

    onUnmounted(() => {
      clearInterval(intervalNotification.value)
    })

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      isDev,

      notifications,
      intervalNotification,
      resolveIcon,
      toggleAlert,
      fetchNotification,
      configOrganization,

      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiClose,
        mdiMessageOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.navbar-content-container-notification {
  display: block;
  margin-inline: 1.5rem;
  margin-top: 75px;
  position: relative;
  padding-left: 260px;
  transition-duration: 0.5s;

}
.navbar-content-container-notification-alert {
  max-width: calc(1440px - 3rem);
  margin-inline: auto;
}
.navbar-content-container-notification-pl {
  padding-left: 68px !important;
  transition-duration: 0.5s;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1263.98px) {
  .navbar-content-container-notification {
    padding-left: 0px;
    transition-duration: 0.5s;
    transition: 0.5s;

  }
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
