import { apiHttp } from '../axiosApi'

// eslint-disable-next-line object-curly-newline
import { cancellationReasonPath, cancellationReasonGroup } from '../config/apiRoute'

export const getCancellationReasons = () => apiHttp('GET', `${cancellationReasonPath}`)
export const getCancellationReason = async id => apiHttp('GET', `${cancellationReasonPath}${id}`)
export const getCancellationReasonsByGroup = id => apiHttp('GET', `${cancellationReasonGroup}${id}`)
export const createCancellationReason = async body => apiHttp('POST', `${cancellationReasonPath}`, body)
export const editCancellationReason = async (body, id) => apiHttp('PUT', `${cancellationReasonPath}${id}`, body)
export const deleteCancellationReason = async id => apiHttp('DELETE', `${cancellationReasonPath}${id}`)
