import {
  mdiCurrencyUsd,
  mdiVideoOutline,
  mdiFileDocumentOutline,
  mdiBell,
  mdiAccountGroupOutline,
  mdiChartTimelineVariant,
  mdiForumOutline,
  mdiClipboardTextSearchOutline,
  mdiCircleOutline,
  mdiPointOfSale,
  mdiOfficeBuildingOutline,
  mdiCogOutline,
  mdiFaceAgent,
  mdiCellphone,
  mdiCashRegister,
  mdiAccountMultipleOutline,
  mdiFinance,
  mdiCreditCardSearchOutline,
  mdiPackageVariantClosed,
  mdiCalendarCheckOutline,
} from '@mdi/js'
import { copy, openChat } from '@core/utils'

export default [
  // ----------FACILITY---------------------
  {
    id: [1, 19, 2, 5, 13, 21, 10, 8, 23, 24, 25, 26, 27, 28, 29, 30, 35, 36],
    subheader: 'FACILITY',
  },
  {
    id: [23, 31, 35],
    title: 'Bookings',
    icon: mdiCalendarCheckOutline,
    to: 'views-booking-view',
  },

  // {
  //   id: 23,
  //   title: 'Schedule',
  //   icon: mdiCalendarMonth,
  //   to: 'views-reservations-schedule',
  // },
  {
    id: 36,
    title: 'Contacts',
    icon: mdiAccountMultipleOutline,
    to: 'views-contact-list',
  },
  {
    id: 24,
    title: 'Payments',
    icon: mdiCurrencyUsd,
    to: 'views-pos-payment-list',
  },

  // {
  //   id: 35,
  //   title: 'Bookings',
  //   icon: mdiCalendarCheckOutline,
  //   to: 'views-recurring-reservation-list',
  // },
  {
    id: [1, 5, 13, 21, 19],
    title: 'Videos',
    icon: mdiVideoOutline,
    to: 'views-video-view',
  },

  // {
  //   id: [1, 5, 13, 21],
  //   title: 'Videos',
  //   icon: mdiVideoOutline,
  //   children: [
  //     {
  //       id: 1,
  //       title: 'Order Videos',
  //       icon: mdiCircleOutline,
  //       to: 'views-order-video-list',
  //       child: true,
  //     },
  //     {
  //       id: 5,
  //       title: 'Recordings',
  //       icon: mdiCircleOutline,
  //       to: 'views-video-list',
  //       child: true,
  //     },
  //     {
  //       id: 13,
  //       title: 'Highlights',
  //       icon: mdiCircleOutline,
  //       to: 'views-highlight-list',
  //       child: true,
  //     },
  //     {
  //       id: 21,
  //       title: 'Field Monitoring',
  //       icon: mdiCircleOutline,
  //       to: 'views-field-monitoring-list',
  //       child: true,
  //     },
  //   ],
  // },

  // {
  //   id: 19,
  //   title: 'Recurring Orders',
  //   icon: mdiContentDuplicate,
  //   to: 'views-recurring-order-list',
  // },

  {
    id: 2,
    title: 'Order History',
    icon: mdiFileDocumentOutline,
    to: 'views-order-history-list',
  },

  {
    id: 25,
    title: 'Groups',
    icon: mdiAccountGroupOutline,
    to: 'views-players-group-list',
  },
  {
    id: 26,
    title: 'POS',
    icon: mdiCashRegister,
    to: 'views-checkout',
  },
  {
    id: [27, 28, 29],
    title: 'Inventory',
    icon: mdiPackageVariantClosed,
    children: [
      {
        id: 27,
        title: 'Products',
        icon: mdiCircleOutline,
        to: 'views-product-list',
        child: true,
      },
      {
        id: 28,
        title: 'Products Categories',
        icon: mdiCircleOutline,
        to: 'views-products-categories-list',
        child: true,
      },
      {
        id: 29,
        title: 'Inventory Adjustment',
        icon: mdiCircleOutline,
        to: 'views-inventory-list',
        child: true,
      },
    ],
  },
  {
    id: [8, 10, 30, 9],
    title: 'Settings',
    icon: mdiCogOutline,
    to: 'views-facility-settings-view',
  },

  // {
  //   id: [8, 10, 30, 38],
  //   title: 'Settings',
  //   icon: mdiCogOutline,
  //   children: [
  //     {
  //       id: 10,
  //       title: 'Fields',
  //       icon: mdiCircleOutline,
  //       to: 'views-field-list',
  //       child: true,
  //     },
  //     {
  //       id: 8,
  //       title: 'Cameras',
  //       icon: mdiCircleOutline,
  //       to: 'views-camera-list',
  //       child: true,
  //     },
  //     {
  //       id: 30,
  //       title: 'Onsite Payments Methods',
  //       icon: mdiCircleOutline,
  //       to: 'onsite-payment-method-list',
  //       child: true,
  //     },
  //     {
  //       id: 38,
  //       title: 'Blocked Times',
  //       icon: mdiCircleOutline,
  //       to: 'views-blocked-times-list',
  //       child: true,
  //     },
  //   ],
  // },

  // ----------ORGANIZATION---------------------
  {
    id: [20, 6, 16, 3, 4],
    subheader: 'ORGANIZATION',
  },
  {
    id: [20],
    title: 'Reports',
    icon: mdiFinance,
    to: 'views-reports-view',
  },

  // {
  //   id: [20],
  //   title: 'Reports',
  //   icon: mdiFinance,
  //   children: [
  //     {
  //       id: 20,
  //       title: 'Dashboard',
  //       icon: mdiCircleOutline,
  //       to: 'views-dashboard',
  //       child: true,
  //     },

  //     // {
  //     //   id: 20,
  //     //   title: 'Booking ***',
  //     //   icon: mdiCircleOutline,
  //     //   child: true,
  //     // },
  //   ],
  // },

  {
    id: [6, 16],
    title: 'Users',
    icon: mdiAccountMultipleOutline,
    children: [
      {
        id: 6,
        title: 'Users',
        icon: mdiCircleOutline,
        to: 'views-user-list',
        child: true,
      },
      {
        id: 16,
        title: 'Roles',
        icon: mdiCircleOutline,
        to: 'views-role-list',
        child: true,
      },
    ],
  },

  {
    id: [3, 4],
    title: 'Billing History',
    icon: mdiCreditCardSearchOutline,
    to: 'views-billing-view',
  },

  {
    id: [8, 10, 30, 9],
    title: 'Settings',
    icon: mdiCogOutline,
    to: 'views-organization-settings-view',
  },

  // {
  //   id: [3, 4],
  //   title: 'Billing',
  //   icon: mdiCreditCardSearchOutline,
  //   children: [
  //     {
  //       id: 3,
  //       title: 'Billing History',
  //       icon: mdiCircleOutline,
  //       to: 'views-payment-list',
  //       child: true,
  //     },
  //     {
  //       id: 4,
  //       title: 'Payment Methods',
  //       icon: mdiCircleOutline,
  //       to: 'views-payment-method-list',
  //       child: true,
  //     },
  //   ],
  // },

  // {
  //   id: [3],
  //   title: 'Settings',
  //   icon: mdiVideoOutline,
  //   children: [
  //     {
  //       id: 3,
  //       title: 'General',
  //       icon: mdiCircleOutline,
  //       child: true,
  //     },
  //   ],
  // },

  // FANATY
  {
    id: [15, 9, 7, 12, 14, 22, 18, 11, 17, 37],
    subheader: 'FANATY',
  },
  {
    id: 15,
    title: 'Organizations',
    icon: mdiOfficeBuildingOutline,
    to: 'views-group-list',
  },

  // {
  //   id: 9,
  //   title: 'Facilities',
  //   icon: mdiHomeGroup,
  //   to: 'views-facility-list',
  // },
  {
    id: [7, 12, 14, 22],
    title: 'Application',
    icon: mdiCellphone,
    children: [
      {
        id: 7,
        title: 'Users',
        icon: mdiCircleOutline,
        to: 'views-user-app-list',
        child: true,
      },
      {
        id: 12,
        title: 'Activity',
        icon: mdiCircleOutline,
        to: 'views-user-activity-list',
        child: true,
      },
      {
        id: 14,
        title: 'Notifications',
        icon: mdiCircleOutline,
        to: 'views-notification-list',
        child: true,
      },
      {
        id: 22,
        title: 'Payments',
        icon: mdiCircleOutline,
        to: 'views-fanaty-payment-list',
        child: true,
      },
    ],
  },
  {
    id: 18,
    title: 'Updates',
    icon: mdiBell,
    to: 'views-console-notification-list',
  },

  {
    id: [11],
    title: 'Settings',
    icon: mdiCogOutline,
    to: 'views-fanaty-settings-view',
  },

  // {
  //   id: [11],
  //   title: 'Settings',
  //   icon: mdiCogOutline,
  //   children: [
  //     {
  //       id: 11,
  //       title: 'Sports',
  //       icon: mdiCircleOutline,
  //       to: 'views-sport-list',
  //       child: true,
  //     },
  //   ],
  // },
  {
    id: [17, 37],
    title: 'Support',
    icon: mdiFaceAgent,
    children: [
      {
        id: 17,
        title: 'Chat',
        icon: mdiCircleOutline,
        child: true,
        custom: 'chat',
        action: openChat,
      },
      {
        id: 37,
        title: 'Phone Number',
        icon: mdiCircleOutline,
        child: true,
        custom: 'phone',
        action: copy,
      },
    ],
  },

  // ----------v2---------------------
  {
    id: [31, 32, 33, 34],
    subheader: 'v2',
  },
  {
    id: 31,
    title: 'Reservation',
    icon: mdiClipboardTextSearchOutline,
    to: 'views-console-reservation',
  },
  {
    id: 32,
    title: 'Metrics',
    icon: mdiChartTimelineVariant,
    to: 'views-console-metrics',
  },
  {
    id: 33,
    title: 'Communications',
    icon: mdiForumOutline,
    to: 'views-console-communications',
  },
  {
    id: 34,
    title: 'Terminals',
    icon: mdiPointOfSale,
    to: 'views-terminal-list',
  },
]
